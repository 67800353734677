// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:8513ca78-cae0-48f7-9c64-901502eb0cba",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_kyipI6zdS",
    "aws_user_pools_web_client_id": "4q1um59mhi1ih1ht3m51e4rjck",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://qp3zozanarhk5iqwdjkx2qxina.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "progresstodojs-20191117085028-hostingbucket-newdev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://drt1rjt3t3r7s.cloudfront.net"
};


export default awsmobile;
